import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import FormulaCategoriesIndex from "../controllers/FormulaCategoriesIndex";
import FormulasIndex from "../controllers/FormulasIndex";
import FormulaOptionsIndex from "../controllers/FormulaOptionsIndex";
import CitiesIndex from "../controllers/CitiesIndex";
import PlanningIndex from "../controllers/PlanningIndex";
import PlanificationIndex from "../controllers/PlanificationIndex";
import PlanificationValidation from "../controllers/PlanificationValidation";
import SessionsForm from "../utils/SessionsForm";
import InstructorCandidatesIndex from "../controllers/InstructorCandidatesIndex";
import DrivingInstructorCandidatesForm from "../controllers/DrivingInstructorCandidatesForm";
import ProspectsNew from "../controllers/ProspectsNew";
import ProspectsEdit from "../controllers/ProspectsEdit";
import ProspectsIndex from "../controllers/ProspectsIndex";
import ProspectsMap from "../controllers/ProspectsMap";
import FormulasForm from "../controllers/FormulasForm";
import DrivingCoursesIndex from "../controllers/DrivingCoursesIndex";
import DrivingCoursesNotAffected from "../controllers/DrivingCoursesNotAffected";
import DrivingCoursesShow from "../controllers/DrivingCoursesShow";
import DrivingCoursesSatisfaction from "../controllers/DrivingCoursesSatisfaction";
import ProspectsSessionForm from "../controllers/ProspectsSessionForm";
import ClientsIndex from "../controllers/ClientsIndex";
import ClientsEdit from "../controllers/ClientsEdit";
import DrivingInstructorIndex from "../controllers/DrivingInstructorIndex";
import DrivingInstructorsForm from "../controllers/DrivingInstructorsForm";
import DrivingTestsIndex from "../controllers/DrivingTestsIndex";
import DrivingTestsRdvPermis from "../controllers/DrivingTestsRdvPermis";
import DrivingTestsCandidates from "../controllers/DrivingTestsCandidates";
import DrivingTestsResults from "../controllers/DrivingTestsResults";
import TasksIndex from "../controllers/TasksIndex";
import FormulaCategoriesForm from "../controllers/FormulaCategoriesForm";

import AccountingIndex from "../controllers/AccountingIndex";
import AccountingFinances from "../controllers/AccountingFinances";
import AccountingPayments from "../controllers/AccountingPayments";
import AccountingFreeLessonReporting from "../controllers/AccountingFreeLessonReporting";

import BackofficeDrivingTestsNew from "../controllers/DrivingTestsNew";
import DrivingCoursesShowSession from "../controllers/DrivingCoursesShowSession";
import CodeShow from "../controllers/CodeShow";
import DrivingCoursesCpfSessions from "../controllers/DrivingCoursesCpfSessions";
import DrivingCoursesEvaluation from "../controllers/DrivingCoursesEvaluation";

import PartnersFormulasIndex from "../../partners/controllers/FormulasIndex.js";
import SessionNotificationsIndex from "../controllers/SessionNotificationsIndex";

import CpfIndex from "../controllers/CpfIndex";
import ProspectsStats from "../controllers/ProspectsStats";
import CitiesForm from "../controllers/CitiesForm";
import InitialAssessmentCategoriesIndex from "../controllers/initial_assessment/InitialAssessmentCategoriesIndex";
import InitialAssessmentQuestionsIndex from "../controllers/initial_assessment/InitialAssessmentQuestionsIndex";
import InitialAssessmentQuestionsForm from "../controllers/initial_assessment/InitialAssessmentQuestionsForm";

import BackofficeInstructorsBonuses from "../controllers/BackofficeInstructorsBonuses";
import BackofficeSupplyRequestsIndex from "../controllers/BackofficeSupplyRequestsIndex";

import RdvpermisLessonsIndex from "../controllers/RdvpermisLessonsIndex";
export default class Routes extends ConfigRoutes{

    static routes = {
        "BackofficeDashboardIndex": DashboardIndex,
        "BackofficePermissionsIndex": PermissionsIndex,
        "BackofficeAdminsIndex": AdminsIndex,
        "BackofficeAdminsEdit": AdminsEdit,
        "BackofficeAdminsUpdate": AdminsEdit,
        "BackofficeAdminsNew": AdminsNew,
        "BackofficeAdminsCreate": AdminsNew,
        "BackofficeRolesIndex": RoleIndex,
        "BackofficeRolesEdit": RoleEdit,
        "BackofficeRolesNew": RoleEdit,
        "BackofficeRolesUpdate": RoleEdit,
        "BackofficeRolesCreate": RoleEdit,
        "BackofficeProfileIndex": ProfileIndex,
        "BackofficeFormulaCategoriesIndex": FormulaCategoriesIndex,
        "BackofficeFormulaCategoriesNew": FormulaCategoriesForm,
        "BackofficeFormulaCategoriesEdit": FormulaCategoriesForm,
        "BackofficeFormulaCategoriesCreate": FormulaCategoriesForm,
        "BackofficeFormulaCategoriesUpdate": FormulaCategoriesForm,
        "BackofficeFormulaOptionsIndex": FormulaOptionsIndex,
        "BackofficeFormulasIndex": FormulasIndex,
        "BackofficeCitiesNew": CitiesForm,
        "BackofficeCitiesCreate": CitiesForm,
        "BackofficeCitiesEdit": CitiesForm,
        "BackofficeCitiesUpdate": CitiesForm,
        "BackofficeCitiesIndex": CitiesIndex,
        "BackofficeSessionsNew": SessionsForm,
        "BackofficeSessionsEdit": SessionsForm,
        "BackofficeSessionsUpdate": SessionsForm,
        "BackofficeSessionsCreate": SessionsForm,
        "BackofficeInstructorCandidatesIndex": InstructorCandidatesIndex,
        "BackofficeInstructorCandidatesFavorites": InstructorCandidatesIndex,
        "BackofficeInstructorCandidatesEdit": DrivingInstructorCandidatesForm,
        "BackofficeInstructorCandidatesNew": DrivingInstructorCandidatesForm,
        "BackofficeInstructorCandidatesUpdate": DrivingInstructorCandidatesForm,
        "BackofficeInstructorsIndex": DrivingInstructorIndex,
        "BackofficePlanningIndex": PlanningIndex,
        "BackofficePlanningGeneration": PlanificationIndex,
        "BackofficePlanningNoDrivingInstructor": PlanificationIndex,
        "BackofficePlanningSessionValidation": PlanificationValidation,
        "BackofficeProspectsNew": ProspectsNew,
        "BackofficeProspectsCreate": ProspectsNew,
        "BackofficeProspectsEdit": ProspectsEdit,
        "BackofficeProspectsIndex": ProspectsIndex,
        "BackofficeProspectsMap": ProspectsMap,
        "BackofficeProspectsStats": ProspectsStats,
        "BackofficeSessionNotificationsIndex": SessionNotificationsIndex,
        "BackofficeFormulasEdit": FormulasForm,
        "BackofficeFormulasUpdate": FormulasForm,
        "BackofficeFormulasNew": FormulasForm,
        "BackofficeFormulasCreate": FormulasForm,
        "BackofficeDrivingCoursesIndex": DrivingCoursesIndex,
        "BackofficeDrivingCoursesNotAffected": DrivingCoursesNotAffected,
        "BackofficeDrivingCoursesOnlineSessions": DrivingCoursesIndex,
        "BackofficeDrivingCoursesShowSession": DrivingCoursesShowSession,
        "BackofficeDrivingCoursesShowSessionListing": DrivingCoursesShowSession,
        "BackofficeDrivingCoursesShow": DrivingCoursesShow,
        "BackofficeDrivingCoursesShowListing": DrivingCoursesShow,
        "BackofficeDrivingCoursesShowNotAffected": DrivingCoursesShow,
        "BackofficeDrivingCoursesCpfSessions": DrivingCoursesCpfSessions,
        "BackofficeDrivingCoursesSatisfaction": DrivingCoursesSatisfaction,
        "BackofficeDrivingCoursesNotSatisfied": DrivingCoursesSatisfaction,
        "BackofficeDrivingCoursesSatisfactionResults": DrivingCoursesSatisfaction,
        "BackofficeDrivingCoursesEvaluation": DrivingCoursesEvaluation,
        "BackofficeProspectsEditSession": ProspectsSessionForm,
        "BackofficeProspectsEditInvoiceSession": ProspectsSessionForm,
        "BackofficeClientsIndex": ClientsIndex,
        "BackofficeClientsEdit": ClientsEdit,
        "BackofficeInstructorsEdit": DrivingInstructorsForm,
        "BackofficeInstructorsUpdate": DrivingInstructorsForm,
        "BackofficeDrivingTestsIndex": DrivingTestsIndex,
        "BackofficeDrivingTestsNew": BackofficeDrivingTestsNew,
        "BackofficeDrivingTestsCreate": BackofficeDrivingTestsNew,
        "BackofficeDrivingTestsEdit": BackofficeDrivingTestsNew,
        "BackofficeDrivingTestsUpdate": BackofficeDrivingTestsNew,
        "BackofficeDrivingTestsRdvPermis": DrivingTestsRdvPermis,
        "BackofficeDrivingTestsCandidates": DrivingTestsCandidates,
        "BackofficeDrivingTestsResults": DrivingTestsResults,
        "BackofficeTasksIndex": TasksIndex,
        "BackofficeAccountingIndex": AccountingIndex,
        "BackofficeAccountingFinances": AccountingFinances,
        "BackofficeAccountingPayments": AccountingPayments,
        "BackofficeAccountingAdditionalHoursPayments": AccountingPayments,
        "BackofficeAccountingExternalPayments": AccountingPayments,
        "BackofficeAccountingPaymentsValidation": AccountingPayments,
        "BackofficeAccountingFreeLessonReporting": AccountingFreeLessonReporting,
        "BackofficeCodeShow": CodeShow,
        "BackofficeCpfIndex": CpfIndex,
        "BackofficeCpfUnclassified": CpfIndex,

        /*PartnerBackoffice*/
        "PartnersProfileIndex": ProfileIndex,
        "PartnersPlanningIndex": PlanningIndex,
        "PartnersInstructorsIndex": DrivingInstructorIndex,
        "PartnersPlanningGeneration": PlanificationIndex,
        "PartnersPlanningSessionValidation": PlanificationValidation,
        "PartnersInstructorsEdit": DrivingInstructorsForm,
        "PartnersInstructorsUpdate": DrivingInstructorsForm,

        "PartnersDrivingCoursesIndex": DrivingCoursesIndex,
        "PartnersDrivingCoursesNotAffected": DrivingCoursesIndex,
        "PartnersDrivingCoursesOnlineSessions": DrivingCoursesIndex,
        "PartnersDrivingCoursesShowSession": DrivingCoursesShowSession,
        "PartnersDrivingCoursesShowSessionListing": DrivingCoursesShowSession,
        "PartnersDrivingCoursesShow": DrivingCoursesShow,
        "PartnersDrivingCoursesShowListing": DrivingCoursesShow,
        "PartnersDrivingCoursesShowNotAffected": DrivingCoursesShow,
        "PartnersFormulasIndex": PartnersFormulasIndex,
        "PartnersAccountingIndex": AccountingPayments,
        "PartnersClientsIndex": ClientsIndex,

        "BackofficeInitialAssessmentCategoriesIndex": InitialAssessmentCategoriesIndex,
        "BackofficeInitialAssessmentQuestionsIndex": InitialAssessmentQuestionsIndex,
        "BackofficeInitialAssessmentQuestionsNew": InitialAssessmentQuestionsForm,
        "BackofficeInitialAssessmentQuestionsCreate": InitialAssessmentQuestionsForm,
        "BackofficeInitialAssessmentQuestionsEdit": InitialAssessmentQuestionsForm,
        "BackofficeInitialAssessmentQuestionsUpdate": InitialAssessmentQuestionsForm,

        "BackofficeInstructorsBonuses": BackofficeInstructorsBonuses,
        "BackofficeSupplyRequestsIndex": BackofficeSupplyRequestsIndex,

        "BackofficeRdvpermisLessonsIndex": RdvpermisLessonsIndex
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}